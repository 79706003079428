<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 17:38:22
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
</style>
<template>
  <basic-layout>
    <router-view></router-view>
  </basic-layout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";

export default {
  name: "User",
  components: {
    BasicLayout,
  },
  methods: {},
};
</script>
